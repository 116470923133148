<template>
  <v-select
    v-model="countryId"
    :label="label || 'País'"
    item-text="name"
    item-value="id"
    :items="countries"
    :error-messages="errors"
    id="mySelect"
  >
    <template v-slot:selection="{ item }">
      <div class="v-select-country">
        <img :src="item.flag" class="img-fluid" />
        <span class="me-auto" style="font-size: 14px;">{{ item.name }}</span>
      </div>
    </template>
    <template v-slot:item="{ item }">
      <div class="v-select-country">
        <img :src="item.flag" class="img-fluid" />
        <span>{{ item.name }}</span>
        <span class="text-muted ms-auto">{{ item.prefix }}</span>
      </div>
    </template>
  </v-select>
</template>

<script>
/**
 * Props:
 * value: valor del componente
 * errors: los errores de validación
 * countries: array de países
 * label: etiqueta del control
 */
export default {
  props: ["value", "errors", "countries", "label"],
  computed: {
    countryId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    countryId(value) {
      this.$emit('onChangeCountry', value);
    },
  },
};
</script>
