<template>
  <v-main class="bg-body-color main">
    <div class="wrapper__auth-new">
      <div class="auth__logo">
        <img src="@/assets/images/logo.svg" alt="Geor, registro" />
      </div>
      <div class="auth__box">
        <h6 class="auth__box-h6">Crear cuenta</h6>
        <p class="auth__box-p">Ingresa tus datos para crear tu cuenta.</p>
        <br />
        <ValidationObserver v-slot="{ invalid }" tag="div" ref="formRegister">
          <form @submit.prevent="onSubmit" autocomplete="off">
            <ValidationProvider name="país" vid="country_id" rules="required" v-slot="{ errors }">
              <geor-select-countries
                :countries="countries"
                :errors="errors"
                v-model="form.country_id"
                @onChangeCountry="onCaptureCountry"
              ></geor-select-countries>
            </ValidationProvider>
            <ValidationProvider
              name="teléfono"
              vid="phone"
              rules="required|numeric|min:8|max:12"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="form.phone"
                :prefix="form.prefix"
                label="Teléfono"
                :error-messages="errors"
                v-mask="'############'"
              ></v-text-field>
            </ValidationProvider>
            <br />
            <div class="text-center">
              <v-btn
                :disabled="invalid || loading"
                :loading="loading"
                color="primary"
                depressed
                rounded
                class="v-btn-pad-x-large"
                type="submit"
                >SIGUIENTE</v-btn
              >
              <br /><br /><br />
              <router-link :to="{ name: 'RegisterEmail' }">
                Quiero registrarme usando mi correo
              </router-link>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <p class="text--secondary mb-5">
        ¿Ya tienes una cuenta?
        <router-link class="auth__link color-primary" :to="{ name: 'LoginEmail' }"
          >Inicia sesión.
        </router-link>
      </p>
      <BtnWhatsapp />
      <br />
      <p class="text-center auth__mw text--secondary">
        Al continuar acepta los
        <a :href="`${baseUrl}terminos-y-condiciones`">Términos y Condiciones</a> y
        <a :href="`${baseUrl}politicas-de-privacidad`">Políticas de Privacidad</a> de Geor.
      </p>
    </div>
  </v-main>
</template>

<script>
import SelectCountries from "@/components/partials/SelectCountries.vue";
import { mapState } from "vuex";

export default {
  components: {
    "geor-select-countries": SelectCountries,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_URL_WEB,
      form: {
        country_id: "PE",
        prefix: "+51",
        phone: "",
      },
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.countries,
      loading: (state) => state.loading,
    }),
  },
  mounted() {
    if (this.countries.length === 0) {
      this.$store.dispatch("LOAD_COUNTRIES");
    }
  },
  methods: {
    onSubmit() {
      this.$axios
        .post("auth/register", this.form)
        .then(() => {
          this.$router.push({
            name: "CheckCode",
            query: {
              phone: this.form.phone,
              country_id: this.form.country_id,
              prefix: this.form.prefix,
            },
          });
        })
        .catch((error) => this.showErrors(error, "formRegister"));
    },
    onCaptureCountry(countryId) {
      const country = this.countries.find((c) => c.id === countryId);
      this.form.prefix = country.prefix;
    },
  },
};
</script>
